
import { Component, Vue, Watch } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import ApplicantsDraggableListHeader from "@/components/talent/tracker/ApplicantsDraggableListHeader.vue"
import MultiSortableList from "@/components/widgets/sortable/MultiSortableList.vue"
import SortableItem from "@/components/widgets/sortable/SortableItem.vue"
import ApplicantDetail from "@/views/talent/ApplicantDetail.vue"
import DocumentCard from "@/components/content/DocumentCard.vue"
import UserAvatar from "@/components/profile/UserAvatar.vue"

import {
  AllApplicantsQuery,
  ConsultantTalent,
  ApplicantDetailQuery,
  ListApplicantsQuery,
  ProceedApplicationMutation,
} from "@/gql"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"

@Component({
  components: {
    AppPage,
    ApplicantsDraggableListHeader,
    MultiSortableList,
    SortableItem,
    ApplicantDetail,
    DocumentCard,
    UserAvatar,
  },
  apollo: {
    applications: {
      query: AllApplicantsQuery,
      variables() {
        return this.queryVars
      },
      update(data) {
        this.loading = false
        this.isFetching = false
        const init: Record<string, any> = {}

        const grouped = Object.entries(data).reduce((acc: any, curr: any) => {
          acc[curr[0]] = curr[1].data

          return acc
        }, init)

        return grouped
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loading = false
          this.isFetching = false
          this.errorLoading = true
          this.applicant = null
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      errorPolicy: "all",
    },
  },
})
export default class ApplicantsManager extends Vue {
  readonly query = AllApplicantsQuery

  loading = true
  isFetching = false
  isSaving = false
  saved = false
  updateFailed = false
  showApplicantModal = false
  loadingMore = false
  isLoadingDetails = false
  networkError = false
  errorLoading = false

  applications: Record<string, any[]> = {}
  selectedApplicant: ConsultantTalent | null = null
  tab = "profile"
  _timeout: number | undefined
  filter: FilterType = {}
  currentState: string | null = null

  @Watch("filter")
  onFilterChange() {
    this.isFetching = true
    this.$apollo.queries.applications?.refetch()
  }

  get queryVars() {
    return {
      ...this.filter,
      per: 20,
      page: 1,
    }
  }

  save() {
    this.saved = true
    this.isSaving = false

    clearTimeout(this._timeout)

    this._timeout = window.setTimeout(() => {
      this.saved = false
    }, 4000)
  }

  onSortApplications({
    item,
    prevEvent,
    nextEvent,
    oldIndex,
    newIndex,
  }: {
    item: any
    prevEvent: string
    nextEvent: string
    oldIndex: number
    newIndex: number
  }) {
    if (!item.nextStates.map((s: any) => s.state).includes(nextEvent)) {
      setTimeout(() => {
        this.resetDraggedItem({ item, prevEvent, nextEvent, oldIndex, newIndex })
      }, 10)
    } else {
      setTimeout(() => {
        this.onProceedApplication({ item, prevEvent, nextEvent, newIndex, oldIndex })
      }, 1000)
    }
  }

  async onProceedApplication({
    item,
    prevEvent,
    nextEvent,
    newIndex,
    oldIndex,
  }: {
    item: ConsultantTalent
    prevEvent: string
    nextEvent: string
    newIndex: number
    oldIndex: number
  }) {
    if (!item.nextStates.map((s: any) => s.state).includes(nextEvent)) {
      return
    }

    const event = item.nextStates.find((state: any) => state.state === nextEvent)?.event

    if (event) {
      this.isSaving = true

      const { data } = await this.mutate({
        mutation: ProceedApplicationMutation,
        variables: {
          id: item.id,
          nextEvent: event,
        },
        refetchQueries: [{ query: this.query, variables: this.queryVars }],
      })

      if (data && !data.proceedApplication.error) {
        this.save()
      } else {
        this.resetDraggedItem({ item, prevEvent, nextEvent, oldIndex, newIndex })
        this.updateFailed = true
        this.isSaving = false
        clearTimeout(this._timeout)

        this._timeout = window.setTimeout(() => {
          this.updateFailed = false
        }, 2000)
      }
    }
  }

  resetDraggedItem({
    item,
    prevEvent,
    nextEvent,
    oldIndex,
    newIndex,
  }: {
    item: any
    prevEvent: string
    nextEvent: string
    oldIndex: number
    newIndex: number
  }) {
    const sourceList = this.applications[prevEvent]
    const targetList = this.applications[nextEvent]

    // remove dragged item from target list
    targetList.splice(newIndex, 1)

    // move dragged item back to its original position
    sourceList.splice(oldIndex, 0, item)

    this.$forceUpdate()
  }

  async loadMore(state: string) {
    this.currentState = state

    this.loadingMore = true

    const { data } = await this.$apollo.query({
      query: ListApplicantsQuery,
      variables: {
        filter: {
          ...this.filter,
          state,
        },
        per: 20,
        page: Math.floor(this.applications[state].length / 20) + 1,
      },
    })

    this.loadingMore = false

    if (!data) {
      return this.addError("Error loading more applicants")
    }

    this.applications[state] = [...this.applications[state], ...data.applicants.data]
  }

  async fetchApplicantDetail(id: string) {
    this.showApplicantModal = true
    this.tab = "profile"
    this.isLoadingDetails = true

    const { data } = await this.$apollo.query({
      query: ApplicantDetailQuery,
      variables: {
        filter: {
          idIn: [id],
        },
        per: 1,
        page: 1,
      },
    })

    this.isLoadingDetails = false

    if (!data) {
      return this.addError("Failed to fetch applicant details")
    }

    this.selectedApplicant = data.applicants.data[0]
  }

  gotoDetailPage(applicant: ConsultantTalent) {
    this.showApplicantModal = true
    this.fetchApplicantDetail(applicant.id)
  }

  getDetailRoute(applicant: ConsultantTalent) {
    return {
      name: this.$routes.TalentApplicantsConsultantDetail,
      params: { id: applicant.id.toString() },
    }
  }

  getColor(key: string) {
    switch (key) {
      case "pending":
        return "#720000"
      case "intro_call":
        return "#BDBDBD"
      case "shortlisted":
        return "#F1CF79"
      case "first_final_interview":
        return "#DCE775"
      case "second_final_interview":
        return "#C0CA33"
      case "first_case_study":
      case "second_case_study":
        return "#0288D1"
      case "rejected":
        return "#CE4257"
    }
  }

  getStatusHeading(state: string) {
    return this.sanitizeUnderscores(state)
  }

  async refetchQuery() {
    this.$apollo.queries.applications.refetch()
  }
}
