var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-col"},[_c('div',{staticClass:"d-flex justify-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"circular-chip mr-3",style:({
          backgroundColor: _vm.color,
          color: _vm.color,
        })}),_c('h4',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.title))])]),_c('span',[_c('v-chip',{staticClass:"grey lighten-2 px-2",attrs:{"x-small":""}},[_c('span',{staticStyle:{"font-size":"10px","color":"#8c8c8c","font-weight":"700"}},[_vm._v(_vm._s(_vm.listCount))])])],1)]),_c('div',{staticClass:"mt-2 w-100 rounded",style:({
      height: '4px',
      backgroundColor: _vm.color,
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }