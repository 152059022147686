import { render, staticRenderFns } from "./ApplicantsDraggableListHeader.vue?vue&type=template&id=b1910a70&scoped=true&"
import script from "./ApplicantsDraggableListHeader.vue?vue&type=script&lang=ts&"
export * from "./ApplicantsDraggableListHeader.vue?vue&type=script&lang=ts&"
import style0 from "./ApplicantsDraggableListHeader.vue?vue&type=style&index=0&id=b1910a70&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1910a70",
  null
  
)

export default component.exports